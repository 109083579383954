<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs :tabBarStyle="{
        margin: '0',
      }" v-model="activeIndex">
        <a-tab-pane v-for="(item, index) in filedList" :key="index" :tab="item.name">
        </a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <div class="left category">
        <a-space>
          <span>文档分类：</span>
          <span class="item" v-for="(item, index) in categoryList" :key="item"
            :class="activeCategoryIndex === index ? 'active' : ''" @click="activeCategoryIndex = index">{{ item }}</span>
        </a-space>

        <div style="margin-left: auto">

          <div v-if="activeFieldValue === 'brand'">
            <a-button type="primary" v-if="$getPermission($route.path + '/add-brand')"
              @click="$router.push($route.path + '/add-brand?type=' + activeFieldValue)">新增</a-button>
          </div>
          <div v-else-if="activeFieldValue === 'safeproduct'">
            <a-button type="primary" v-if="$getPermission($route.path + '/add-production')"
              @click="$router.push($route.path + '/add-production?type=' + activeFieldValue)">新增</a-button>
          </div>
          <div v-else>
            <a-button type="primary" v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add?type=' + activeFieldValue)">新增</a-button>
          </div>


        </div>
      </div>
      <div class="category">
        <a-space>
          <span>详细分类：</span>
          <span class="item" v-for="(item, index) in typeList" :key="item.id"
            :class="activeTypeIndex === index ? 'active' : ''" @click="activeTypeIndex = index">{{ item.subCategory
            }}</span>
        </a-space>
      </div>

      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="名称" />
        </a-form-model-item>

        <!-- 证书 -->
        <template v-if="activeType.id === '1'">
          <a-form-item>
            <a-select style="width: 150px" v-model="form.level" placeholder="等级">
              <a-select-option v-for="item in cert_level" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-model-item>
            <a-range-picker v-model="form.dateAchieve" :placeholder="['开始取得日期', '结束取得日期']" style="width: 260px" />
          </a-form-model-item>
        </template>

        <!-- 质量体系文件 -->
        <template v-if="activeType.id === '2'">
          <a-form-item>
            <a-input placeholder="编号" v-model="form.code" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.type" placeholder="类型">
              <a-select-option v-for="item in doc_document_type" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <!-- 科研成果 -->
        <template v-if="activeType.id === '5'">
          <a-form-item>
            <a-select style="width: 150px" v-model="form.type" placeholder="类型">
              <a-select-option v-for="item in doc_achievement_type" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <!-- 专利：发明/实用新型/外观 -->
        <template v-if="['6', '7', '8'].includes(activeType.id)">
          <a-form-item>
            <a-input placeholder="专利号" v-model="form.number" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" placeholder="是否有效" v-model="form.inforce">
              <a-select-option v-for="item in booleanList" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker :placeholder="['专利申请日期', '专利申请日期']" v-model="form.dateApply" style="width: 260px" />
          </a-form-item>
          <a-form-item>
            <a-range-picker :placeholder="['专利授权日期', '专利授权日期']" v-model="form.dateAuthorization" style="width: 260px" />
          </a-form-item>
        </template>

        <!-- 著作权 -->
        <template v-if="activeType.id === '9'">
          <a-form-item>
            <a-input placeholder="登记号" v-model="form.number" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" placeholder="作品类别" v-model="form.type">
              <a-select-option v-for="item in doc_works_cate" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.registration" placeholder="登记状态">
              <a-select-option v-for="item in doc_registration" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker :placeholder="['初次发布日期', '初次发布日期']" v-model="form.dateFirstPublish" style="width: 260px" />
          </a-form-item>
        </template>

        <!-- 工法 -->
        <template v-if="activeType.id === '10'">
          <a-form-item>
            <a-input v-model="form.code" placeholder="工法编号" />
          </a-form-item>

          <a-form-item>
            <a-input v-model="form.approvalNumber" placeholder="批准文号" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.level" placeholder="级别">
              <a-select-option v-for="item in doc_law_level" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker :placeholder="['开始取得日期', '结束取得日期']" v-model="form.dateHold" style="width: 260px" />
          </a-form-item>
        </template>
        <!-- 项目奖项 -->
        <template v-if="activeType.id === '11'">
          <a-form-item>
            <a-input v-model="form.projectName" placeholder="获奖项目" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.level" placeholder="奖项级别">
              <a-select-option v-for="item in doc_award_level" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input v-model="form.winner" placeholder="获奖人员" />
          </a-form-item>

          <a-form-item>
            <a-range-picker :placeholder="['开始获奖日期', '结束获奖日期']" v-model="form.dateAward" style="width: 260px" />
          </a-form-item>
        </template>
        <!-- 企业荣誉 -->
        <template v-if="activeType.id === '12'">
          <a-form-item>
            <a-select style="width: 150px" v-model="form.level" placeholder="级别">
              <a-select-option v-for="item in doc_award_level" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker :placeholder="['开始获奖日期', '结束获奖日期']" v-model="form.dateAward" style="width: 260px" />
          </a-form-item>
        </template>
        <!-- 专家库 -->
        <template v-if="activeType.id === '13'">
          <a-form-item>
            <a-input v-model="form.expType" placeholder="专家类型" />
          </a-form-item>

          <a-form-item>
            <a-input v-model="form.expArea" placeholder="擅长领域" />
          </a-form-item>
        </template>
        <!-- 企业标准图集 -->
        <template v-if="activeType.id === '14'">
          <a-form-item>
            <a-input v-model="form.number" placeholder="图集编号" />
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.major" placeholder="专业类型">
              <a-select-option v-for="item in majorTypes" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-range-picker :placeholder="['开始发布日期', '结束发布日期']" v-model="form.datePublish" style="width: 260px" />
          </a-form-item>
        </template>
        <!-- 主编标准/参编标准 -->
        <template v-if="activeType.id === '15' || activeType.id === '16'">
          <a-form-item>
            <a-select style="width: 150px" v-model="form.type" placeholder="类型">
              <a-select-option v-for="item in doc_std_level" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-select style="width: 150px" v-model="form.major" placeholder="专业类型">
              <a-select-option v-for="item in majorTypes" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </template>
        <a-form-model-item v-if="activeType.id!=6 && activeType.id!=7 && activeType.id!=8 && activeType.id!=9 && activeType.id!=10">
          <a-range-picker v-model="form.createAt" style="width: 260px" :placeholder="['开始创建日期', '结束创建日期']" />
        </a-form-model-item>
        <a-form-model-item v-if="activeType.id ==6 || activeType.id ==7 || activeType.id ==8">
          <a-input v-model="form.inventor" placeholder="发明人" />
        </a-form-model-item>
        <a-form-model-item v-if="activeType.id == 9">
          <a-input v-model="form.copyrightOwner" placeholder="著作权人" />
        </a-form-model-item>
        <a-form-model-item v-if="activeType.id == 10">
          <a-input v-model="form.people" placeholder="工法完成人" />
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
        <a-table-column key="name" title="名称">
          <template slot-scope="text">
            <a href="#" v-if="$getPermission($route.path + '/detail')" @click.prevent="
              $router.push(
                $route.path + '/detail?id=' + text.id + '&pid=' + text.pid
              )
              ">{{ text.name }}</a>
            <span v-else>{{ text.name }}</span>
          </template>
        </a-table-column>

        <!-- 证书 -->
        <template v-if="activeType.id === '1'">
          <a-table-column key="level" title="证书等级" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="cert_level" :dictValue="text.level" />
            </template>
          </a-table-column>

          <a-table-column key="dateAchieve" title="取得日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateAchieve }}
            </template>
          </a-table-column>
        </template>

        <!-- 质量体系文件 -->
        <template v-if="activeType.id === '2'">
          <a-table-column key="code" title="编号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.code }}
            </template>
          </a-table-column>

          <a-table-column key="type" title="类型" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_document_type" :dictValue="text.type" />
            </template>
          </a-table-column>
          <a-table-column key="version" title="版本" align="center" width="100px">
            <template slot-scope="text">
              {{ text.version }}
            </template>
          </a-table-column>
        </template>

        <!-- 质量管理制度 -->
        <template v-if="activeType.id === '4'">
          <a-table-column key="publishNum" title="发文号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.publishNum }}
            </template>
          </a-table-column>

          <a-table-column key="datePublish" title="发布日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.datePublish }}
            </template>
          </a-table-column>
        </template>

        <!-- 科研成果 -->
        <template v-if="activeType.id === '5'">
          <a-table-column key="subject" title="主题词" align="center" width="100px">
            <template slot-scope="text">
              {{ text.subject }}
            </template>
          </a-table-column>
          <a-table-column key="charger" title="项目负责人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.charger }}
            </template>
          </a-table-column>

          <a-table-column key="chargerDept" title="主承担部门" align="center" width="100px">
            <template slot-scope="text">
              {{ text.chargerDept }}
            </template>
          </a-table-column>

          <a-table-column key="dateSetup" title="立项日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateSetup }}
            </template>
          </a-table-column>

          <a-table-column key="dateFinish" title="完成日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateFinish }}
            </template>
          </a-table-column>
        </template>

        <!-- 专利：发明/实用新型/外观 -->
        <template v-if="['6', '7', '8'].includes(activeType.id)">
          <a-table-column key="number" title="专利号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.number }}
            </template>
          </a-table-column>

          <a-table-column key="patentee" title="专利权人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.patentee }}
            </template>
          </a-table-column>

          <a-table-column key="inventor" title="发明人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.inventor }}
            </template>
          </a-table-column>

          <a-table-column key="dateApply" title="专利申请日" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateApply }}
            </template>
          </a-table-column>

          <a-table-column key="dateAuthorization" title="专利授权日" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateAuthorization }}
            </template>
          </a-table-column>

          <a-table-column key="inforce" title="是否有效" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="boolean" :dictValue="String(text.inforce)" />
            </template>
          </a-table-column>
        </template>

        <!-- 著作权 -->
        <template v-if="activeType.id === '9'">
          <a-table-column key="number" title="登记号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.number }}
            </template>
          </a-table-column>

          <a-table-column key="type" title="作品类别" align="center" width="100px">
            <template slot-scope="text">
              {{ text.number }}
              <DataDictFinder dictType="doc_works_cate" :dictValue="text.type" />
            </template>
          </a-table-column>

          <a-table-column key="authorName" title="作者" align="center" width="100px">
            <template slot-scope="text">
              {{ text.authorName }}
            </template>
          </a-table-column>

          <a-table-column key="copyrightOwner" title="著作权人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.copyrightOwner }}
            </template>
          </a-table-column>

          <a-table-column key="registration" title="登记状态" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_registration" :dictValue="text.registration" />
            </template>
          </a-table-column>

          <a-table-column key="dateCompletion" title="创作完成日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateCompletion }}
            </template>
          </a-table-column>

          <a-table-column key="dateFirstPublish" title="初次发布日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateFirstPublish }}
            </template>
          </a-table-column>
        </template>

        <!-- 工法 -->
        <template v-if="activeType.id === '10'">
          <a-table-column key="code" title="工法编号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.code }}
            </template>
          </a-table-column>

          <a-table-column key="approvalNumber" title="批准文号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.approvalNumber }}
            </template>
          </a-table-column>

          <a-table-column key="level" title="等级" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_law_level" :dictValue="text.level" />
            </template>
          </a-table-column>

          <a-table-column key="people" title="工法完成人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.people }}
            </template>
          </a-table-column>

          <a-table-column key="deptName" title="完成单位" align="center" width="100px">
            <template slot-scope="text">
              {{ text.deptName }}
            </template>
          </a-table-column>
          <a-table-column key="dateHold" title="取得日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateHold }}
            </template>
          </a-table-column>
        </template>
        <!-- 项目奖项 -->
        <template v-if="activeType.id === '11'">
          <a-table-column key="projectName" title="获奖项目" align="center">
            <template slot-scope="text">
              {{ text.projectName }}
            </template>
          </a-table-column>

          <a-table-column key="projectType" title="项目类型" align="center">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_prj_cate" :dictValue="text.projectType" />
            </template>
          </a-table-column>
          <a-table-column key="charger" title="项目负责人" align="center">
            <template slot-scope="text">
              {{ text.charger }}
            </template>
          </a-table-column>

          <a-table-column key="level" title="奖项级别" align="center">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_award_level" :dictValue="text.level" />
            </template>
          </a-table-column>

          <a-table-column key="winner" title="获奖人员" align="center">
            <template slot-scope="text">
              {{ text.winner }}
            </template>
          </a-table-column>

          <a-table-column key="awardOrg" title="评奖机构" align="center">
            <template slot-scope="text">
              {{ text.awardOrg }}
            </template>
          </a-table-column>

          <a-table-column key="dateAward" title="获奖日期" align="center">
            <template slot-scope="text">
              {{ text.dateAward }}
            </template>
          </a-table-column>
        </template>
        <!-- 企业荣誉 -->
        <template v-if="activeType.id === '12'">
          <a-table-column key="level" title="荣誉等级" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="doc_award_level" :dictValue="text.level" />
            </template>
          </a-table-column>

          <a-table-column key="dateAward" title="获奖日期" align="center" width="160px">
            <template slot-scope="text">
              {{ text.dateAward }}
            </template>
          </a-table-column>
          <a-table-column key="awardOrg" title="评奖机构" align="center" width="160px">
            <template slot-scope="text">
              {{ text.awardOrg }}
            </template>
          </a-table-column>
        </template>
        <!-- 专家库 -->
        <template v-if="activeType.id === '13'">
          <a-table-column key="expType" title="专家类型" align="center" width="100px">
            <template slot-scope="text">
              {{ text.expType }}
            </template>
          </a-table-column>

          <a-table-column key="dateEngageFrom" title="开始聘任日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateEngageFrom }}
            </template>
          </a-table-column>

          <a-table-column key="dateEngageTo" title="截至聘任日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateEngageTo }}
            </template>
          </a-table-column>

          <a-table-column key="expArea" title="擅长领域" align="center" width="100px">
            <template slot-scope="text">
              {{ text.expArea }}
            </template>
          </a-table-column>
        </template>
        <!-- 企业标准图集 -->
        <template v-if="activeType.id === '14'">
          <a-table-column key="number" title="图集编号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.number }}
            </template>
          </a-table-column>

          <a-table-column key="orgComplete" title="完成单位" align="center" width="100px">
            <template slot-scope="text">
              {{ text.orgComplete }}
            </template>
          </a-table-column>

          <a-table-column key="major" title="专业类型" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
            </template>
          </a-table-column>

          <a-table-column key="datePublish" title="发布日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.datePublish }}
            </template>
          </a-table-column>
        </template>
        <!-- 主编标准/参编标准 -->
        <template v-if="['15', '16'].includes(activeType.id)">
          <a-table-column key="code" title="标准编号" align="center" width="100px">
            <template slot-scope="text">
              {{ text.code }}
            </template>
          </a-table-column>

          <a-table-column key="drafter" title="参编人" align="center" width="100px">
            <template slot-scope="text">
              {{ text.drafter }}
            </template>
          </a-table-column>

          <a-table-column key="major" title="专业类型" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
            </template>
          </a-table-column>

          <a-table-column key="datePublish" title="发布日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.datePublish }}
            </template>
          </a-table-column>

          <a-table-column key="dateImplement" title="实施日期" align="center" width="100px">
            <template slot-scope="text">
              {{ text.dateImplement }}
            </template>
          </a-table-column>

          <a-table-column key="orgPublish" title="发布单位" align="center" width="100px">
            <template slot-scope="text">
              {{ text.orgPublish }}
            </template>
          </a-table-column>
        </template>

        <a-table-column key="img" title="缩略图" align="center" width="120px"
          v-if="filedList[activeIndex] && filedList[activeIndex].value === 'brand'">
          <template slot-scope="text">
            <img v-if="getImage(text)" :src="getImage(text)" alt="" style="width: 64px;height: 64px;border-radius: 8px;">
            <span v-else>--</span>
          </template>
        </a-table-column>
        <a-table-column key="size" title="大小" align="center" width="80px"
          v-if="filedList[activeIndex] && filedList[activeIndex].value === 'brand'">
          <template slot-scope="text">
            {{ getSize(text) }}
          </template>


        </a-table-column>
        <a-table-column key="createUser" title="创建人" data-index="createBy" align="center" width="80px"></a-table-column>

        <a-table-column key="createAt" title="创建日期" data-index="createAt" align="center" width="160px"></a-table-column>

        <a-table-column key="control" title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a href="#" v-if="$getPermission($route.path + '/detail')" @click.prevent="
                download(text)
                ">下载</a>
              <a href="#" v-if="$getPermission($route.path + '/detail')" @click.prevent="
                $router.push(
                  $route.path + '/detail?id=' + text.id + '&pid=' + text.pid
                )
                ">查看</a>



              <span v-if="activeFieldValue === 'brand'">
                <a href="#" v-if="$getPermission($route.path + '/edit-brand')" @click.prevent="
                  $router.push(
                    $route.path + '/edit-brand?id=' + text.id + '&pid=' + text.pid + '&type=' + activeFieldValue
                  )
                  ">编辑</a>
              </span>
              <span v-else-if="activeFieldValue === 'safeproduct'">
                <a href="#" v-if="$getPermission($route.path + '/edit-production')" @click.prevent="
                  $router.push(
                    $route.path + '/edit-production?id=' + text.id + '&pid=' + text.pid + '&type=' + activeFieldValue
                  )
                  ">编辑</a>
              </span>
              <span v-else>
                <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent="
                  $router.push(
                    $route.path + '/edit?id=' + text.id + '&pid=' + text.pid + '&type=' + activeFieldValue
                  )
                  ">编辑</a>
              </span>
              <span v-if="activeFieldValue === 'brand'">
                <a href="#" v-if="$getPermission($route.path + '/delete-brand')" class="danger"
                  @click.prevent="deleteText(text)">删除</a>
              </span>
              <span v-else-if="activeFieldValue === 'safeproduct'">
                <a href="#" v-if="$getPermission($route.path + '/delete-production')" class="danger"
                  @click.prevent="deleteText(text)">删除</a>
              </span>
              <span v-else>
                <a href="#" v-if="$getPermission($route.path + '/delete')" class="danger"
                  @click.prevent="deleteText(text)">删除</a>
              </span>


            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>


    <a-modal title="下载" :visible="visible" :footer="null" @cancel="visible = false; text = {}">
      <a-table bordered :data-source="Array.isArray(text.attachmentList) ? text.attachmentList : []" :pagination="false">
        <a-table-column title="文件名" data-index="name" />
        <a-table-column title="操作" align="center" width="80px">
          <template slot-scope="text">
            <a target="_blank" :href="text.completePath">下载</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchCategory, fetchList, remove } from "@/api/knowledge";
import { mapGetters } from "vuex";

export default {
  name: "inKnowledge",
  data() {
    return {
      activeIndex: 0,

      category: {},
      categoryList: [],
      activeCategoryIndex: 0,
      activeTypeIndex: 0,

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      text: {},
      visible: false,
    };
  },

  watch: {
    activeIndex() {
      this.getData();
    },
    activeCategoryIndex() {
      if (this.activeTypeIndex !== 0) {
        this.activeTypeIndex = 0;
      } else {
        this.clear();
        this.getList();
      }
    },

    activeTypeIndex() {
      this.clear();
      this.getList();
    },
  },

  computed: {

    activeFieldValue() {
      const obj = this.filedList[this.activeIndex]
      return obj ? obj.value : ''
    },

    typeList() {
      const list = this.category[this.categoryList[this.activeCategoryIndex]];
      return Array.isArray(list) ? list : [];
    },

    activeType() {
      const type = this.typeList[this.activeTypeIndex];
      return type ? type : {};
    },

    ...mapGetters("setting", ["findDataDict"]),

    filedList() {
      return this.findDataDict('field')
    },

    booleanList() {
      return this.findDataDict("boolean");
    },

    majorTypes() {
      return this.findDataDict("majorTypes");
    },

    doc_document_type() {
      return this.findDataDict("doc_document_type");
    },
    doc_achievement_type() {
      return this.findDataDict("doc_achievement_type");
    },
    doc_valid() {
      return this.findDataDict("doc_valid");
    },
    cert_level() {
      return this.findDataDict("cert_level");
    },
    doc_works_cate() {
      return this.findDataDict("doc_works_cate");
    },
    doc_law_level() {
      return this.findDataDict("doc_law_level");
    },
    doc_award_level() {
      return this.findDataDict("doc_award_level");
    },
    doc_std_level() {
      return this.findDataDict("doc_std_level");
    },
    doc_prj_cate() {
      return this.findDataDict("doc_prj_cate");
    },
    doc_registration() {
      return this.findDataDict("doc_registration");
    },
  },

  mounted() {
    this.getData();
  },

  methods: {

    clear() {
      // 清空查询条件
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.total = 0;
      this.list = []
    },

    getData() {
      fetchCategory({
        field: this.filedList[this.activeIndex]?.value,
      }).then((res) => {
        console.log('res', res)
        if (res) {
          this.category = res;
          this.categoryList = Object.keys(res);



          if (this.activeCategoryIndex !== 0) {
            this.activeCategoryIndex = 0;
          } else {
            this.clear();
            this.getList();
          }
        }
      });
    },

    getList() {
      const pid = this.typeList[this.activeTypeIndex]?.id;
      if (!pid) return;
      const { current, pageSize } = this;

      const {
        dateAchieve,
        dateApply,
        dateAuthorization,
        dateFirstPublish,
        dateHold,
        dateAward,
        datePublish,
        createAt,
      } = this.form;

      const params = {};
      if (Array.isArray(dateAchieve) && dateAchieve.length === 2) {
        params.dateAchieveStartDate = dateAchieve[0].format("YYYY-MM-DD");
        params.dateAchieveEndDate = dateAchieve[1].format("YYYY-MM-DD");
      }
      if (Array.isArray(dateApply) && dateApply.length === 2) {
        params.dateApplyStartDate = dateApply[0].format("YYYY-MM-DD");
        params.dateApplyEndDate = dateApply[1].format("YYYY-MM-DD");
      }
      if (Array.isArray(dateAuthorization) && dateAuthorization.length === 2) {
        params.dateAuthorizationStartDate =
          dateAuthorization[0].format("YYYY-MM-DD");
        params.dateAuthorizationEndDate =
          dateAuthorization[1].format("YYYY-MM-DD");
      }

      if (Array.isArray(dateFirstPublish) && dateFirstPublish.length === 2) {
        params.dateFirstPublishStartDate =
          dateFirstPublish[0].format("YYYY-MM-DD");
        params.dateFirstPublishEndDate =
          dateFirstPublish[1].format("YYYY-MM-DD");
      }

      if (Array.isArray(dateHold) && dateHold.length === 2) {
        params.dateHoldStartDate = dateHold[0].format("YYYY-MM-DD");
        params.dateHoldEndDate = dateHold[1].format("YYYY-MM-DD");
      }

      if (Array.isArray(dateAward) && dateAward.length === 2) {
        params.dateAwardStartDate = dateAward[0].format("YYYY-MM-DD");
        params.dateAwardEndDate = dateAward[1].format("YYYY-MM-DD");
      }

      if (Array.isArray(datePublish) && datePublish.length === 2) {
        params.datePublishStartDate = datePublish[0].format("YYYY-MM-DD");
        params.datePublishEndDate = datePublish[1].format("YYYY-MM-DD");
      }

      if (Array.isArray(createAt) && createAt.length === 2) {
        params.createAtStartDate = createAt[0].format("YYYY-MM-DD");
        params.createAtEndDate = createAt[1].format("YYYY-MM-DD");
      }
      this.loading = true;
      fetchList({
        pid,
        pageNum: current,
        pageSize,
        ...this.form,
        ...params,

        dateAchieve: undefined,
        dateApply: undefined,
        dateAuthorization: undefined,
        dateFirstPublish: undefined,
        dateHold: undefined,
        dateAward: undefined,
        datePublish: undefined,
        createAt: undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
            pid: text.pid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    getImage(text) {
      let path = '';
      if (Array.isArray(text.attachmentList)) {
        for (let i = 0; i < text.attachmentList.length; i++) {
          const item = text.attachmentList[i];
          if (item) {
            if (item.ext === 'png' || item.ext === 'jpg' || item.ext === 'jpeg' || item.ext === 'webp' || item.ext === 'gif') {
              path = item.completePath;
            }
          }
        }
      }

      return path ? this.$imgCrop(path, 60, 60) : ''
    },
    getSize(text) {
      let size = 0;
      if (Array.isArray(text.attachmentList)) {
        text.attachmentList.forEach(item => {
          size += item.size
        });
      }

      return this.getfilesize(size);
    },

    getfilesize(size) {
      if (!size) return "--";
      var num = 1024.00; //byte
      if (size < num) {
        return size + "B";
      } else if (size < Math.pow(num, 2)) {
        return (size / num).toFixed(2) + "KB"; //kb
      } else if (size < Math.pow(num, 3)) {
        return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M

      } else if (size < Math.pow(num, 4)) {
        return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      } else {
        return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T

      }
    },


    download(text) {
      this.text = text;
      this.visible = true;
    }

  },
};
</script>

<style lang="less" scoped>
.category {
  margin-bottom: 16px;

  .item {
    cursor: pointer;
    padding: 4px 8px;
  }

  .active {
    background-color: #1890ff;
    color: #fff;
  }
}
</style>